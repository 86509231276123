import { render, staticRenderFns } from "./UprnInfo.vue?vue&type=template&id=e137114c&scoped=true"
import script from "./UprnInfo.vue?vue&type=script&lang=js"
export * from "./UprnInfo.vue?vue&type=script&lang=js"
import style0 from "./UprnInfo.vue?vue&type=style&index=0&id=e137114c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e137114c",
  null
  
)

export default component.exports