import Vue from "vue"

const state = {
    availableOptions: {
        class_generated: [],
        class_name: [],
        openreach_type: [],
        mobile_coverage: [],
        counties: [],
        countries: [],
        districts: [],
        parishes: [],
        national_parks: [],
        westminster_constituency: [],
        tax_bands: [],
        business_rate_exist: null,
        polygons: [],
        fixed_line_providers: [],
    },
    historyItems: [],
    newHistoryItem: {
        class_generated: [],
        class_name: [],
        postcode: null,
        openreach_type: [],
        openreach_download_speed: {min: null, max: null, fixed: null},
        mobile_coverage: [],
        allUprns: false,
        condense_addesses: false,
        counties: [],
        countries: [],
        districts: [],
        parishes: [],
        national_parks: [],
        westminster_constituency: [],
        tax_bands: [],
        business_rate_exist: null,
        polygons: [],
        fixed_line_providers: [],

    },
    selectedPolygons: [],
}
const actions = {}
const getters = {
    historyItems: state => state.historyItems,
    newHistoryItem: state => state.newHistoryItem,
    availableOptions: state => state.availableOptions,
    selectedPolygons: state => state.selectedPolygons,
}
const mutations = {
    setAvailableOptions(state, data) {
        Vue.set(state, 'availableOptions', data)
    },
    setHistoryItems(state, data) {
        Vue.set(state, 'historyItems', data);
    },
    setSelectedPolygons(state, data) {
        Vue.set(state, 'selectedPolygons', data);
    }
}


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
